/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-01-04",
    versionChannel: "nightly",
    buildDate: "2023-01-04T00:14:51.216Z",
    commitHash: "664a86161ac55eee8afd61c067b50578191c1088",
};
